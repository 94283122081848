import * as React from "react"
import { galleryKeys, gallery } from "../../data/gallery"
import { translations } from "../../translations/translations"
import { images as imageLinks } from "../../data/variables"
import { observer } from "mobx-react-lite"
import "./Gallery.scss"

const Gallery = observer(() => {
  const [category, setCategory] = React.useState<string>("all")
  const [image, setImage] = React.useState<number>(0)

  const imageNumber = image + 1
  const images =
    category === "all"
      ? [].concat.apply([], Object.values(gallery))
      : gallery[category]

  const handleSetCategory = (cat: string) => {
    setCategory(cat)
    setImage(0)
  }

  const pagination = () => {
    return (
      <div className="pagination d-flex">
        <div
          className="arrow"
          onClick={() =>
            setImage(prevImg =>
              prevImg == 0 ? images.length - 1 : prevImg - 1
            )
          }
        >
          <img src={imageLinks.galleryButton} />
        </div>
        <div className="no">
          {imageNumber > 9 ? (
            imageNumber
          ) : (
            <span>
              0<span className="primary-color">{imageNumber}</span>
            </span>
          )}
        </div>
        <div
          className="arrow arrow-right"
          onClick={() =>
            setImage(prevImg =>
              prevImg === images.length - 1 ? 0 : prevImg + 1
            )
          }
        >
          <img className="rotate-180" src={imageLinks.galleryButton} />
        </div>
      </div>
    )
  }

  return (
    <div className='gallery-container'>
      <div className="gallery" id="gallery">
        <div className="inner w-90 d-flex">
          <div className="controls">
            <div className="up">
              <div className="title">
                <h4>{translations.gallery.title}</h4>
              </div>
              <div className="categories">
                <ul>
                  {galleryKeys.map(k => (
                    <li
                      key={k}
                      className={k === category ? "active" : ""}
                      onClick={() => handleSetCategory(k)}
                    >
                      {translations.gallery[k]}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {pagination()}
          </div>
          <div
            className="image"
            style={{ background: `url('${images[image]}')` }}
          />
        </div>
      </div>
      <div
        className="mobile-gallery-image"
        style={{ background: `url('${images[image]}')` }}
      />
      <div className="image-name">
        <div className="inner w-90 d-flex">
          <div className='mp-container'>
            <div className="mobile-pagination">{pagination()}</div>
          </div>
          <div className="name">
            <span>{translations.gallery[category]}</span>
            <h4>Delovi za centrifuge</h4>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Gallery
