import * as React from "react"
import "./Assortment.scss"

interface Props {
  image: any
  name: string
  description: string
  link: string
}

const Assortment: React.FC<Props> = ({ image, name, description, link }) => {
  const handleOnClick = () =>
  {
    window.open(link);
  }
  return (
    <div className="assortment-item" onClick={handleOnClick}>
      <div className="inner">
        <div className="image">
          <img src={image} alt="" />
        </div>
        <div className="name">
          <h4 dangerouslySetInnerHTML={{ __html: name }} />
        </div>
        <div className="description">{description}</div>
      </div>
    </div>
  )
}

export default Assortment
