import * as React from "react"
import { images } from "../../data/variables"

const Loader = () => {
  return (
    <div className="loader">
      <div
        className="ldBar"
        data-type="fill"
        data-img={images.logo}
        data-img-size="100,100"
        data-value="0"
      />
      {/* <span>Loading</span> */}
    </div>
  )
}

export default Loader
