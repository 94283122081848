import * as React from "react"
import { images } from "../../data/variables"
import { translations } from "../../translations/translations"
import "./FeaturedProduct.scss"

interface Props {
  name: string
  link?: string
  features?: string[]
  category?: string
  image?: string
  type?: string
  description?: string
  shortDescription?: string
}

const FeaturedProduct: React.FC<Props> = ({
  name,
  link,
  features,
  category,
  image,
  type,
  description,
  shortDescription,
}) => {
  return (
    <div className="featured-product">
      <div className="inner d-flex">
        <div className="left">
          <div className="name">
            <h4>{name}</h4>
          </div>
          <div className="short-description d-flex flex-column">
            <h4>{type}</h4>
            <span>{shortDescription}</span>
          </div>
          <div className="description">
            <span>{description}</span>
          </div>
          <div className="link">
            <a href={link} target="_blank" className="button">{translations.navigation.shop}</a>
          </div>
        </div>
        <div className="right" style={{backgroundImage: `url(${image})`}}>
          <div className="features">
            {features?.map((f, i) => (
              <Feature desc={f} key={i} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const Feature = ({ desc }) => {
  if(!desc.length)
  {
    return null;
  }
  return (
    <div className="feature">
      <div className="inner d-flex">
        <div className="description">
          <span dangerouslySetInnerHTML={{__html: desc}}/>
        </div>
        <div className="checkmark">
          <div className="img-container">
            <img src={images.ticked} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedProduct
